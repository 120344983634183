import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useDocumentTitle from '~/hooks/useDocumentTitle';
import SunIcon from '../svg/SunIcon';
import LightningIcon from '../svg/LightningIcon';
import CautionIcon from '../svg/CautionIcon';
import store from '~/store';
import { localize } from '~/localization/Translation';
import { useGetStartupConfig } from 'librechat-data-provider';
import AutoLogout from '../Nav/AutoLogout'; 
import Cookies from 'js-cookie';

import { buildPromptPrefix } from '~/utils/buildPromptPrefix';
import { setUserKeyTypes } from '~/utils/setUserKeyTypes';
import { useLocation } from 'react-router-dom';




export default function Landing() {
  const { data: config } = useGetStartupConfig();
  const setText = useSetRecoilState(store.text);
  const conversation = useRecoilValue(store.conversation);
  const lang = useRecoilValue(store.lang);
  // @ts-ignore TODO: Fix anti-pattern - requires refactoring conversation store
  const { title = localize(lang, 'com_ui_new_chat') } = conversation || {};

  useDocumentTitle(title);

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { innerText } = e.target as HTMLButtonElement;
    const quote = innerText.split('"')[1].trim();
    setText(quote);
  };

  const location = useLocation();
  
  useEffect(() => {
    // get URL parameters
    const params = new URLSearchParams(location.search);
    buildPromptPrefix(params, lang);
    setUserKeyTypes(params);
    
  }, [location]);

  const userTypeTranslated = Cookies.get('userTypeTranslated');
  const usecaseTranslated = Cookies.get('usecaseTranslated');
  const userType = Cookies.get('userType');
  const usecase = Cookies.get('usecase');

  let usecase_example_1;
  let usecase_example_2;
  let usecase_example_3;
  let usecase_example_4;
  let usecase_example_5;
  if (usecase) {
      usecase_example_1 = localize(lang, `com_ui_example_${usecase}_1`);
      usecase_example_2 = localize(lang, `com_ui_example_${usecase}_2`);
      usecase_example_3 = localize(lang, `com_ui_example_${usecase}_3`);
      usecase_example_4 = localize(lang, `com_ui_example_${usecase}_4`);
      usecase_example_5 = localize(lang, `com_ui_example_${usecase}_5`);
  }

  return (
    <div className="flex h-full flex-col items-center overflow-y-auto pt-0 text-sm dark:bg-gray-800" style={{zIndex: 2}}>
      <div className="w-full px-6 text-gray-800 dark:text-gray-100 md:flex md:max-w-2xl md:flex-col lg:max-w-3xl">
        <h1
          id="landing-title"
          className="mb-10 ml-auto mr-auto mt-6 flex items-center justify-center gap-2 text-center text-4xl font-semibold sm:mb-16 md:mt-[10vh]"
        >
          {config?.appTitle || 'SAI'} {userTypeTranslated ? ` cho ${userTypeTranslated}` : null}
        </h1>
        <div className="items-start gap-3.5 text-center md:flex" style={{paddingBottom: "150px"}}>
          <div className="mb-8 flex flex-1 flex-col gap-3.5 md:mb-auto">
            <h2 className="m-auto flex items-center gap-3 text-lg font-normal md:flex-col md:gap-2">
              <SunIcon />
              {localize(lang, 'com_ui_examples')}
            </h2>
            <ul className="m-auto flex w-full flex-col gap-3.5 sm:max-w-md">
              <button
                  onClick={clickHandler}
                  className="w-full rounded-md bg-gray-50 p-3 hover:bg-gray-200 dark:bg-white/5 dark:hover:bg-gray-900"
                >
                &quot;{usecase_example_1 ? usecase_example_1 : localize(lang, 'com_ui_example_everyone_general_1')}&quot; →
              </button>
              <button
                onClick={clickHandler}
                className="w-full rounded-md bg-gray-50 p-3 hover:bg-gray-200 dark:bg-white/5 dark:hover:bg-gray-900"
              >
                &quot;{usecase_example_2 ? usecase_example_2 : localize(lang, 'com_ui_example_everyone_general_2')}&quot; →
              </button>
              <button
                onClick={clickHandler}
                className="w-full rounded-md bg-gray-50 p-3 hover:bg-gray-200 dark:bg-white/5 dark:hover:bg-gray-900"
              >
                &quot;{usecase_example_3 ? usecase_example_3 : localize(lang, 'com_ui_example_everyone_general_3')}&quot; →
              </button> 
              <button
                onClick={clickHandler}
                className="w-full rounded-md bg-gray-50 p-3 hover:bg-gray-200 dark:bg-white/5 dark:hover:bg-gray-900"
              >
                &quot;{usecase_example_4 ? usecase_example_4 : localize(lang, 'com_ui_example_everyone_general_4')}&quot; →
              </button>
              <button
                onClick={clickHandler}
                className="w-full rounded-md bg-gray-50 p-3 hover:bg-gray-200 dark:bg-white/5 dark:hover:bg-gray-900"
              >
                &quot;{usecase_example_5 ? usecase_example_5 : localize(lang, 'com_ui_example_everyone_general_5')}&quot; →
              </button>
            </ul>
          </div>
          <div className="mb-8 flex flex-1 flex-col gap-3.5 md:mb-auto">
            <h2 className="m-auto flex items-center gap-3 text-lg font-normal md:flex-col md:gap-2">
              <LightningIcon />
              {localize(lang, 'com_ui_capabilities')}
            </h2>
            <ul className="m-auto flex w-full flex-col gap-3.5 sm:max-w-md">
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_capability_remember')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_capability_search')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_capability_conversations')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_capability_files_and_plugins')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_capability_correction')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_capability_decline_requests')}
              </li>
            </ul>
          </div>
          <div className="mb-8 flex flex-1 flex-col gap-3.5 md:mb-auto">
            <h2 className="m-auto flex items-center gap-3 text-lg font-normal md:flex-col md:gap-2">
              <CautionIcon />
              {localize(lang, 'com_ui_limitations')}
            </h2>
            <ul className="m-auto flex w-full flex-col gap-3.5 sm:max-w-md">
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_limitation_incorrect_info')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_limitation_sometimes_hangup')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_limitation_sometimes_change_model')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_limitation_limited_2021')}
              </li>
              <li className="w-full rounded-md bg-gray-50 p-3 dark:bg-white/5">
                {localize(lang, 'com_ui_limitation_harmful_biased')}
              </li>
            </ul>
          </div>
        </div>
        <AutoLogout />
        {/* {!showingTemplates && (
          <div className="mt-8 mb-4 flex flex-col items-center gap-3.5 md:mt-16">
            <button
              onClick={showTemplates}
              className="btn btn-neutral justify-center gap-2 border-0 md:border"
            >
              <ChatIcon />
              Show Prompt Templates
            </button>
          </div>
        )}
        {!!showingTemplates && <Templates showTemplates={showTemplates}/>} */}
        {/* <div className="group h-32 w-full flex-shrink-0 dark:border-gray-900/50 dark:bg-gray-800 md:h-48" /> */}
      </div>
    </div>
  );
}
