import React, { useState } from 'react';
import LogoutDialog from '../components/ui/LogoutDialog';

const NOT_ENOUGH_POINT_MESSAGE = import.meta.env.VITE_APP_NOT_ENOUGH_POINT_MESSAGE;

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const getError = (text) => {
  const errorMessage = text.length > 512 ? text.slice(0, 512) + '...' : text;

  // console.log('errorMessage=',errorMessage);


  if (errorMessage === NOT_ENOUGH_POINT_MESSAGE) {
    const [showLogoutDialog, setShowLogoutDialog] = useState(true);

  const handleConfirmLogout = () => {
    // Handle logout logic here, e.g., call your logout function
    // logout();

    // Then close the dialog
    // setShowLogoutDialog(false);
  };

  return (
    <>
      {/* Your error message display logic here */}
      {/* For example, you could display the errorMessage in a <div> */}
      {/* You may be out of point. Please get or buy some in SAI.vn , or try again in a few moments. Here's the details:  */}
      {errorMessage === NOT_ENOUGH_POINT_MESSAGE && (
        <>
        <LogoutDialog
          isOpen={showLogoutDialog}
          onDismiss={() => setShowLogoutDialog(false)}
          onConfirmLogout={handleConfirmLogout}
        />
        </>
      )}
    </>
  );
  }


  const match = text.match(/\{[^{}]*\}/);
  let json = match ? match[0] : '';
  if (isJson(json)) {
    json = JSON.parse(json);
    if (json.code === 'invalid_api_key') {
      return 'Invalid API key. Please check your API key and try again. You can do this by clicking on the model logo in the left corner of the textbox and selecting "Set Token" for the current selected endpoint. Thank you for your understanding.';
    } else if (json.type === 'insufficient_quota') {
      return 'We apologize for any inconvenience caused. The default API key has reached its limit. To continue using this service, please set up your own API key. You can do this by clicking on the model logo in the left corner of the textbox and selecting "Set Token" for the current selected endpoint. Thank you for your understanding.';
    } else { 
      return `Oops! Something went wrong. Please try again in a few moments. Here's the specific error message we encountered: ${errorMessage}`;
    }
  } else {
    return `Oops! Something went wrong. Please try again in a few moments. Here's the specific error message we encountered: ${errorMessage}`;
  }
  
};

export default getError;
