import Cookies from 'js-cookie';
import { localize } from '~/localization/Translation';

export const buildPromptPrefix = (params, lang) => {

  let promptPrefix;
  if (params.has('user_type') || params.has('usecase')) {
    const parts = [];
    let userTypeTranslated;
    let usecaseTranslated;

    parts.push(localize(lang, 'com_prompt_prefix_you_are_ai_assisstant'));

    if (params.has('user_type')) {
      userTypeTranslated = localize(lang, 'com_user_type_' + params.get('user_type'));

      if (params.get('user_type') !== "everyone") {
        parts.push(localize(lang, 'com_prompt_prefix_i_am'));
        parts.push(userTypeTranslated);
        parts.push('.');
      }

      Cookies.set('userType', params.get('user_type'), { expires: 7 });
      Cookies.set('userTypeTranslated', userTypeTranslated, { expires: 7 });
    }

    if (params.has('usecase')) {
      parts.push(localize(lang, 'com_prompt_prefix_i_want'));
      usecaseTranslated = localize(lang, 'com_usecase_' + params.get('usecase'));

      parts.push(usecaseTranslated);
      parts.push('.');

      Cookies.set('usecase', params.get('usecase'), { expires: 7 });
      Cookies.set('usecaseTranslated', usecaseTranslated, { expires: 7 });
    }

    parts.push(localize(lang, 'com_prompt_prefix_you_ask_me_to_help'));

    promptPrefix = parts.join(' ');

    Cookies.set('mPromptPrefix', promptPrefix, { expires: 7 });
  } else {
    // get cookies
    promptPrefix = Cookies.get('mPromptPrefix');
  }

  return promptPrefix;
};
