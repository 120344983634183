import Cookies from 'js-cookie';
// import { localize } from '~/localization/Translation';


export const setUserKeyTypes = (params) => {
    // let userKeyTypes;
    if ( params.has('use_backup') ) {
        Cookies.set('use_backup', params.get('use_backup'), { expires: 7, secure: true });
    }

    if ( params.has('use_api') ) {
        Cookies.set('use_api', params.get('use_api'), { expires: 7, secure: true });
    }

    if ( params.has('use_user_provided_key') ) {
        Cookies.set('use_user_provided_key', params.get('use_user_provided_key'), { expires: 7, secure: true });
    }

    // return promptPrefix;
}