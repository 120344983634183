import React, { Fragment } from 'react';
import { useGetStartupConfig } from 'librechat-data-provider';
import Cookies from 'js-cookie';
const SITE_URL = import.meta.env.VITE_APP_SITE_URL;
const SITE_TAGLINE = import.meta.env.VITE_APP_SITE_TAGLINE;
const SITE_PREMIUM_LINK = import.meta.env.VITE_APP_SITE_PREMIUM_LINK;





export default function Footer() {
  const { data: config } = useGetStartupConfig();

  const userTypeTranslated = Cookies.get('userTypeTranslated');
  const usecaseTranslated = Cookies.get('usecaseTranslated');

  return (
    <Fragment>
      <hr className="mt-3" />
      <div className=" px-3 pb-1 pt-2 text-center text-xs text-black/50 dark:text-white/50 md:block md:px-4 md:pb-4 md:pt-3">
        <a
          href={SITE_URL ? SITE_URL : "https://sai.vn"}
          target="_blank"
          rel="noreferrer"
          className="underline pr-2"
        >
          {config?.appTitle || 'SAI'} {userTypeTranslated ? ` cho ${userTypeTranslated}` : null}
        </a>
        {/* {' - '}. {SITE_TAGLINE ? SITE_TAGLINE : "Sức mạnh từ AI"} {usecaseTranslated ? ` để ${usecaseTranslated}` : null}. */}
        <a
          href={SITE_PREMIUM_LINK ? SITE_PREMIUM_LINK : "https://sai.vn"}
          target="_blank"
          rel="noreferrer"
        ><button className="btn third">Nhận bản VIP không giới hạn</button></a>
      </div>
    </Fragment>
  );
}
