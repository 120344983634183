import React, { FC } from 'react';
import { Dialog, DialogOverlay, DialogContent, DialogTitle, DialogDescription } from '@radix-ui/react-dialog';

const NOT_ENOUGH_POINT_MESSAGE = import.meta.env.VITE_APP_NOT_ENOUGH_POINT_MESSAGE;
const MAIN_SITE_DASHBOARD_URL = import.meta.env.VITE_APP_MAIN_SITE_DASHBOARD_URL;

interface LogoutDialogProps {
  isOpen: boolean;
  onDismiss: () => void;
  onConfirmLogout: () => void;
}

const LogoutDialog: FC<LogoutDialogProps> = ({ isOpen, onDismiss, onConfirmLogout }) => {
  const isNotEnoughPoints = true; // Replace this with your actual condition

  const onConfirmGoToMainSite = () => {
    // window.parent.dispatchEvent(new CustomEvent('closeIframe'));
    // Send a message to the parent frame
    // window.parent.postMessage('closeIframe', '*');
    window.location.href = MAIN_SITE_DASHBOARD_URL; // Redirecting user to the desired URL
  }

  return (
    <Dialog open={isOpen}>
      <DialogOverlay />
      <DialogContent>
        <DialogTitle>{isNotEnoughPoints ? "Tài khoản không đủ điểm!" : 'Error'}</DialogTitle>
        <DialogDescription>
          {isNotEnoughPoints
            ? 'Điểm của bạn không đủ (hoặc không truy vấn được sổ điểm của bạn). Vui lòng nhận hoặc mua điểm ở SAI.vn, hoặc thử lại sau 5ph và báo cho BQT:'
            : 'Oops! Something went wrong. Please try logout and login again, or try again in a few moments.'}
        </DialogDescription>
        <div className="flex justify-end gap-2 mt-4">
          {!isNotEnoughPoints && (<button onClick={onDismiss}>Close</button>)}   
          <button onClick={isNotEnoughPoints ? onConfirmGoToMainSite : onConfirmLogout} className="bg-red-500 text-white px-4 py-2 rounded">
            {isNotEnoughPoints ? 'Quay về SAI.vn' : 'Logout'}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LogoutDialog;
