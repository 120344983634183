import { useAuthContext } from '~/hooks/AuthContext';

const AutoLogout = () => {
  const { user, logout, isAuthenticated } = useAuthContext();

  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  // Check if user.username is empty or not defined
  // if (!user?.username) {
    if (!isAuthenticated) {
    handleLogout(); // Perform the logout action
    console.error("AutoLogout user=",user);
    console.error("AutoLogout isAuthenticated=",isAuthenticated);
  }

  return null; // Do not render anything if auto-logout is triggered

};

export default AutoLogout;